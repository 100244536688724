import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import uploadService from './uploadService'
import { extractErrorMessage } from '../../../../utils'

const file = JSON.parse(localStorage.getItem('file'))

const initialState = {
    file: file ? file : null,
    isLoading: false,
}

export const uploadFile = createAsyncThunk(
    'upload/uploadFile',
    async (file, thunkAPI) => {
        try {
            return await uploadService.uploadFile(file)
        } catch (error) {
            return thunkAPI.rejectWithValue(extractErrorMessage(error))
        }
    }
)

export const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        reset: (state) => {
            state.file = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadFile.pending, (state) => (
                state.isLoading = true
            ))
            .addCase(uploadFile.fulfilled, (state, action) => {
                state.isLoading = false
                state.file = action.payload
            })
            .addCase(uploadFile.rejected, (state, action) => {
                state.isLoading = false
            })
    }
})

export const { reset } = uploadSlice.actions

export default uploadSlice.reducer