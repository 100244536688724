import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from "axios";

import { ReactComponent as ArrowRightIcon } from '../assets/svg/keyboardArrowRightIcon.svg'




function UploadFile() {
  const apiKey = 'AIzaSyBsTTveLDsmi5pLXA_0hYGKDEPzjWU4frU'
  const folderId = '1LooOOeu6VDafD6seceK2hsrY4fjMxTLX'

  const [fileUpload, setFile] = useState(null)

  const onChange = (e) => {
    setFile(e.target.files[0])
  }

  const navigate = useNavigate()

  const url = `https://www.googleapis.com/upload/drive/v3/files?uploadType=media&parents=${folderId}`
  

  const onSubmit = async (e) => {
    e.preventDefault()
    if (fileUpload) {
      try {
        const formData = new FormData();
        formData.append('file', fileUpload);

        const response = await axios.post(url, formData, {
          headers: {
            Authorization: `Bearer ${apiKey}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        if (response.status === 200) {
          console.log('File uploaded successfully!');
          navigate('/')
        } else {
          console.error('Error uploading file:', response.statusText);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }    
  }
  return (
    <>
      <div className="pageContainer">
        <header>
          <p className='pageHeader'>Upload file</p>
        </header>
        <form onSubmit={onSubmit}>
          <input
            type='file'
            onChange={onChange}
          />
          <div className='signupBar'>
            <p className='signupText'>Submit</p>
            <button className="signUpButton" type='submit'>
              <ArrowRightIcon fill='#ffffff' width='34px' height='34px' />
            </button>
          </div>
        </form>
      </div>
    </>

  )
}
export default UploadFile
