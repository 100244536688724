import img from '../assets/coursesImg/s1.jpg'
const CoursesData = [
    {
        id: 1,
        thumbnailUrl: "https://i1-thethao.vnecdn.net/2024/04/12/NovakDjokovicMonteCarlo1-17128-8507-4913-1712868280.jpg?w=240&h=144&q=100&dpr=1&fit=crop&s=HhXYCfuKJ-7cp7JSyabp5Q",
        title: "High School Math (Pure Mathematics 1)",
        instructor: "Ruchi Chihabra",
        rating: 4.7,
        price: "299$"
    },
    {
        id: 2,
        thumbnailUrl: "https://thongtintuyensinh.net/wp-content/uploads/2019/09/thpt-chuyen-tran-dai-nghia-ngung-tuyen-sinh-lop-chuyen-tin.jpg",
        title: "High School Math (Pure Mathematics 1)",
        instructor: "Ruchi Chihabra",
        rating: 4.7,
        price: "299$"
    },
    {
        id: 3,
        thumbnailUrl: img,
        title: "High School Math (Pure Mathematics 1)",
        instructor: "Ruchi Chihabra",
        rating: 4.7,
        price: "299$"
    },

]
export default CoursesData