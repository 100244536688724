import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { register } from '../../store/reducers/features/auth/authSlice'
import Spinner from '../../components/Spinner'

import { ReactComponent as ArrowRightIcon } from '../../assets/svg/keyboardArrowRightIcon.svg'
import visibilityIcon from '../../assets/svg/visibilityIcon.svg'

function SignUp() {
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [formData, setFormData] = useState({
        username: '',
        schoolname: '',
        password: '',
        password2: '',
    })
    const { username, schoolname, password, password2 } = formData
    const { isLoading } = useSelector((state) => state.auth)

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }))
    }    
    
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onSubmit = (e) => {
        e.preventDefault()
        if (password !== password2) {
            toast.error('Passwords do not match')
        } else {
            const userData = {
                username,
                schoolname,
                password,
            }
            dispatch(register(userData)).unwrap()
                .then((user) => {
                    toast.success(`Registered new user - ${user.username}`)
                    navigate('/')
                })
                .catch(toast.error)
        }

    }
    if (isLoading) {
        return <Spinner />
    }
    return (
        <>
            <div className="pageContainer">
                <header>
                    <p className='pageHeader'>Đăng ký </p>
                </header>
                <form onSubmit={onSubmit}>
                    <input
                        type='text'
                        className='nameInput'
                        placeholder='Nhập tên tài khoản'
                        id='username'
                        required
                        value={username}
                        onChange={onChange}
                    />
                    <input
                        type='text'
                        className='schoolInput'
                        placeholder='Nhập tên trường'
                        id='schoolname'
                        value={schoolname}
                        onChange={onChange}
                    />
                    <div className="passwordInputDiv">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            className='passwordInput'
                            placeholder='Nhập mật khẩu'
                            id='password'
                            value={password}
                            onChange={onChange}
                        />
                        <img
                            src={visibilityIcon}
                            alt='show password'
                            className='showPassword'
                            onClick={() => setShowPassword((prevState) => !prevState)}
                        />
                    </div>
                    <div className="passwordInputDiv">
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            className='passwordInput'
                            placeholder='Xác nhận mật khẩu'
                            id='password2'
                            value={password2}
                            onChange={onChange}
                        />

                        <img
                            src={visibilityIcon}
                            alt='show confirm password'
                            className='showPassword'
                            onClick={() => setShowConfirmPassword((prevState) => !prevState)}
                        />
                    </div>

                    <Link
                        to='/forgot-password'
                        className='forgotPasswordLink'>
                        Quên mật khẩu
                    </Link>

                    <div className='signUpBar'>
                        <p className="signUpText">
                            Đăng ký
                        </p>
                        <button className="signUpButton" type='submit'>
                            <ArrowRightIcon fill='#ffffff' width='34px' height='34px' />
                        </button>
                    </div>
                </form>


                <Link to='/sign-in' className='registerLink'>
                    Đăng nhập
                </Link>
            </div>
        </>
    )
}

export default SignUp