import React from 'react';

class TdnLearning extends React.Component {
    render() {
      return (
        <div>
          <h1>Khám phá chương trình luyện thi đầu vào trường chuyên Trần Đại Nghĩa</h1>
        </div>
      );
    }
  }
  
  export default TdnLearning;