import { Navigate, useNavigate } from "react-router-dom"

function CategogyNavBar() {
    const navigate = useNavigate()
    return(
        <div >
            <nav className="navbarNav">
                <ul className="catebarListItems">
                    <li className="navbarListItem" onClick={() => navigate('/tdn')}>
                        <p>Luyện thi trường THCS Trần Đại Nghĩa</p>
                    </li>
                    <li className="navbarListItem" onClick={() => navigate('/sat')}>
                        <p>Luyện thi SAT</p>
                    </li>
                    <li className="navbarListItem" onClick={() => navigate('/grade')}>
                        <p>Ôn luyện kiến thức</p>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
export default CategogyNavBar