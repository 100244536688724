import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../../store/reducers/features/auth/authSlice'
import { toast } from 'react-toastify'
import Spinner from '../../components/Spinner'

import { ReactComponent as ArrowRightIcon } from '../../assets/svg/keyboardArrowRightIcon.svg'
import visibilityIcon from '../../assets/svg/visibilityIcon.svg'

function SignIn() {
    const [showPassword, setShowPassword] = useState(false)
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })
    const { email, password } = formData

    const { isLoading } = useSelector(state => state.auth)

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }))
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onSubmit = (e) => {
        e.preventDefault();
        const userData = {
            email,
            password,
        }
        dispatch(login(userData))
            .unwrap()
            .then((user) => {
                toast.success(`Logged in as ${user.username}`)
                navigate('/')
            })
            .catch(toast.error)
    };

    if (isLoading) {
        return <Spinner />
    }

    return (
        <div className='pageContainer' >
            <header>
                <p className='pageHeader' >
                    Đăng nhập
                </p>
            </header>
            <form onSubmit={onSubmit}>
                <input
                    type='email'
                    className='emailInput'
                    placeholder='Email'
                    id='email'
                    value={email}
                    onChange={onChange}
                />

                <div className="passwordInputDiv" >
                    <input
                        type={showPassword ? 'text' : 'password'}
                        className='passwordInput'
                        placeholder='Password'
                        id='password'
                        value={password}
                        onChange={onChange}
                    />

                    <img
                        src={visibilityIcon}
                        alt='show password'
                        className='showPassword'
                        onClick={() => setShowPassword((prevState) => !prevState)}
                    />
                </div>

                <Link
                    to='/forgot-password'
                    className='forgotPasswordLink'>
                    Đăng nhập
                </Link>

                <div className='signInBar'>
                    <p className="signInText">
                        Đăng nhập
                    </p>
                    <button className="signInButton" type='submit'>
                        <ArrowRightIcon fill='#ffffff' width='34px' height='34px' />
                    </button>
                </div>
            </form>


            <Link to='/sign-up' className='registerLink'>
                Đăng ký
            </Link>
        </div>
    )
}

export default SignIn