import { useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { register } from '../../store/reducers/features/auth/authSlice'
import Spinner from '../../components/Spinner'

function TeacherRegister() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        password2: '',
    })

    const { name, email, password, password2 } = formData

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { isLoading } = useSelector((state) => state.auth)

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    // NOTE: no need for useEffect here as we can catch the
    // AsyncThunkAction rejection in our onSubmit or redirect them on the
    // resolution
    // Side effects shoulld go in event handlers where possible
    // source: - https://beta.reactjs.org/learn/keeping-components-pure#where-you-can-cause-side-effects

    const onSubmit = (e) => {
        e.preventDefault()

        if (password !== password2) {
            toast.error('Passwords do not match')
        } else {
            const userData = {
                name,
                email,
                password,
            }

            dispatch(register(userData))
                .unwrap()
                .then((user) => {
                    // NOTE: by unwrapping the AsyncThunkAction we can navigate the user after
                    // getting a good response from our API or catch the AsyncThunkAction
                    // rejection to show an error message
                    toast.success(`Registered new user - ${user.name}`)
                    navigate('/')
                })
                .catch(toast.error)
        }
    }

    if (isLoading) {
        return <Spinner />
    }

    return (
        <>
            <section className='teacher-heading'>
                <h1>
                    Register
                </h1>
                <p>Please create an account</p>
            </section>

            <section className='auth-form'>
                <form onSubmit={onSubmit}>
                    <div className='auth-form-group'>
                        <input
                            type='text'
                            className='form-control'
                            id='name'
                            name='name'
                            value={name}
                            onChange={onChange}
                            placeholder='Enter your name'
                            required
                        />
                    </div>
                    <div className='auth-form-group'>
                        <input
                            type='email'
                            className='auth-form-control'
                            id='email'
                            name='email'
                            value={email}
                            onChange={onChange}
                            placeholder='Enter your email'
                            required
                        />
                    </div>
                    <div className='auth-form-group'>
                        <input
                            type='password'
                            className='auth-form-control'
                            id='password'
                            name='password'
                            value={password}
                            onChange={onChange}
                            placeholder='Enter password'
                            required
                        />
                    </div>
                    <div className='auth-form-group'>
                        <input
                            type='password'
                            className='auth-form-control'
                            id='password2'
                            name='password2'
                            value={password2}
                            onChange={onChange}
                            placeholder='Confirm password'
                            required
                        />
                    </div>
                    <div className='auth-form-group'>
                        <button className='signInButton'>Submit</button>
                    </div>
                </form>
            </section>

            <Link to='/teacher-login' className='registerLink'>
                Login
            </Link>
        </>
    )
}

export default TeacherRegister