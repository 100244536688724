import PropTypes from 'prop-types';
import './CourseCardItem.css';

function CourseCardItem({ item }) {
    return (
        <div className="course-card">
             <img src={item.thumbnailUrl} alt={item.title} className="course-thumbnail" />
             <div className="course-info">
                 <h3 className="course-title">{item.title}</h3>
                 <p className="course-instructor">Instructor: {item.instructor}</p>
                 <p className="course-price">${item.price}</p>
                 <button className="course-enroll-button">Enroll Now</button>
             </div>
        </div>
        
    )
}
CourseCardItem.propTypes = {
    item: PropTypes.object.isRequired,
}
export default CourseCardItem