import CourseCardItem from "./CourseCardItem"
import "./CourseCardList.css"
import PropTypes from 'prop-types'

function CourseCardList({course}) {
    return (
        <>
            <div className="card-list">
                {course.map((item) => (
                    // <div>{item.price}</div>
                    <CourseCardItem key={item.id} item={item} />
                ))}
            </div>
        </>
    )
}
CourseCardList.propTypes = {
    course: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            instructor: PropTypes.string.isRequired,
            price: PropTypes.string.isRequired,
            thumbnailUrl: PropTypes.string.isRequired,
        })
    )
}

export default CourseCardList