import VideoPlayer from '../../pages/Course/VideoPlayer'
function WishList() {
    return (
        <div className="myCourses">
            <div className="ud-container">
                <h1 className="ud-heading-page-title">
                    Quá trình học tập của tôi
                </h1>
            </div>
            <div className="tabs-module--tabs-container">
                <div className="ud-tabs-nav-buttons">
                    <section className="carousel-module" aria-label="Điều hướng tab">
                        <div className="carousel-module--scroll-port">
                            <div data-index="0" className="carousel-module--scroll-item">
                                <div className="ud-nav-button-container">
                                    <h2>
                                        <a id="tabs-1--tab-0" aria-selected="false" role="tab" tabIndex={-1} href="/courses/all-courses/"
                                            className="ud-btn">
                                            <span>Tất cả khóa học</span>
                                        </a>
                                    </h2>
                                </div>
                            </div>
                            <div data-index="1" className="carousel-module--scroll-item">
                                <div className="ud-nav-button-container">
                                    <h2>
                                        <a id="tabs-1--tab-1" aria-selected="false" role="tab" tabIndex={-1} href="/courses/my-courses/"
                                            className="ud-btn">
                                            <span>Khóa học của tôi</span>
                                        </a>
                                    </h2>
                                </div>
                            </div>
                            <div data-index="2" className="carousel-module--scroll-item">
                                <div className="ud-nav-button-container">
                                    <h2>
                                        <a id="tabs-1--tab-2" aria-selected="true" role="tab" tabIndex={0} href="/courses/wishlist/"
                                            className="ud-btn">
                                            <span>Mong muốn</span>
                                        </a>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="ud-tabs-content" role="tabpanel" id="tabs-1--content-0" tabIndex={-1} aria-labelledby="tabs-1--tab-0"></div>
                <div className="ud-tabs-content" role="tabpanel" id="tabs-1--content-0" tabIndex={-1} aria-labelledby="tabs-1--tab-1"></div>
                <div className="ud-tabs-content--tab-module-active" role="tabpanel" id="tabs-1--content-0" tabIndex={0} aria-labelledby="tabs-1--tab-2">
                    <div class="tabcontent active">
                        <img src="https://kenh14cdn.com/thumb_w/620/2019/4/24/img4694-1556114341696129929485.jpg" width="100%" />
                        <h3 class="ud-heading-md course-card-title-module--course-title">
                            <VideoPlayer></VideoPlayer>
                            <a href="/course/video-player" >TDN</a>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WishList

