import { useState } from "react";
import { useRef } from "react";

import ReactPlayer from "react-player";
import "./VideoPlayer.css";
import Control from "../../container/VideoControls";
import { Container } from "@mui/material";
import { formatTime } from "../../container/Format";

let count = 0

function VideoPlayer() {

  const [videoState, setVideoState] = useState({
    playing: false,
    muted: false,
    volume: 0.5,
    played: 0,
    seeking: false,
    Buffer: true,
    isFullscreen: false
  })
  const { playing, muted, volume, played, seeking, buffer, isFullscreen } = videoState
  const playPauseHandler = () => {
    //plays and pause the video (toggling)
    // setTimeout(setVideoState({ ...videoState, playing: !videoState.playing }),1000)
    setVideoState({ ...videoState, playing: !videoState.playing })
  }

  const videoPlayerRef = useRef(null);
  const rewindHandler = () => {
    //Rewinds the video player reducing 5
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - 5)
  }
  const fastFowardHandler = () => {
    //FastFowards the video player by adding 10
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() + 10)
  }

  const progressHandler = (state) => {
    if (count > 2) {
      // toggling player control container
      controlRef.current.style.visibility = "hidden";
    } else if (controlRef.current.style.visibility === "visible") {
      count += 1;
    }
    if (!seeking) {
      setVideoState({ ...videoState, ...state });
    }
  }
  const seekHandler = (e, value) => {
    setVideoState({ ...videoState, played: parseFloat(value) / 100 })
  }
  const seekMouseUpHandler = (e, value) => {
    setVideoState({ ...videoState, seeking: false })
    videoPlayerRef.current.seekTo(value / 100)
  }

  const volumeChangeHandler = (e, value) => {
    const newVolume = parseFloat(value) / 100;
    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: Number(newVolume) === 0 ? true : false, // volume === 0 then muted
    })

  };
  const volumeSeekUpHandler = (e, value) => {
    const newVolume = parseFloat(value) / 100;
    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: newVolume === 0 ? true : false,
    })
  };

  const muteHandler = () => {
    //Mutes the video player
    setVideoState({ ...videoState, muted: !videoState.muted })
  }

  const currentTime = videoPlayerRef.current ? videoPlayerRef.current.getCurrentTime() : "00:00";
  const duration = videoPlayerRef.current ? videoPlayerRef.current.getDuration() : "00:00";
  const formatCurrentTime = formatTime(currentTime)
  const formatDuration = formatTime(duration)

  const controlRef = useRef(null)
  const mouseMoveHandler = () => {
    controlRef.current.style.visibility = "visible"
    count = 0;
  }

  const bufferStartHandler = () => {
    console.log("Bufering.......");
    setVideoState({ ...videoState, buffer: true })
  };

  const bufferEndHandler = () => {
    console.log("buffering stoped ,,,,,,play");
    setVideoState({ ...videoState, buffer: false })
  };

  const fullScreenHandle = () => {
    var element = document.getElementById("my-video")
    if (!document.fullscreenElement) {
      videoState.isFullscreen = true
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) { /* Firefox */
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { /* IE/Edge */
        element.msRequestFullscreen();
      }
    } else {
      videoState.isFullscreen = false
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    }
    // setVideoState({...videoPlayerRef, isFullscreen: !videoState.isFullscreen})
    // setVideoState({ ...videoState, playing: !videoState.playing })
  }
  return (
    <div className="video_container">
      <div>
        {/* <h2>React player</h2> */}
      </div>
      <Container maxWidth="md" justify="center">
        <div onMouseDown={mouseMoveHandler} >
          <div className="player__wrapper" id="my-video" onDoubleClick={fullScreenHandle} >
            <ReactPlayer
              ref={videoPlayerRef} //updating the react player ref
              className="player"
              url="https://v.vnecdn.net/vnexpress/video/web/mp4/2024/02/26/duoc-si-dua-20-tan-dat-len-san-thuong-lam-vuon-rau-1708943668.mp4"
              width="100%"
              height="100%"
              playing={playing}
              muted={muted}
              isFullscreen={isFullscreen}
              onProgress={progressHandler}
              onBuffer={bufferStartHandler}
              onBufferEnd={bufferEndHandler}
              // onClick={playPauseHandler}

            />
            <Control
              onPlayPause={playPauseHandler}
              playing={playing}

              onRewind={rewindHandler}
              onForward={fastFowardHandler}

              played={played}
              onSeek={seekHandler}
              onSeekMouseUp={seekMouseUpHandler}

              Volume={volume}
              onVolumeChangeHandler={volumeChangeHandler}
              onVolumeSeekUp={volumeSeekUpHandler}

              mute={muted}
              onMute={muteHandler}

              currentTime={formatCurrentTime}
              duration={formatDuration}

              controlRef={controlRef}

              isFullscreen={isFullscreen}
              onFullScreen={fullScreenHandle}
            />
            {buffer && <p>Loading</p>}
          </div>
        </div>
      </Container>

    </div>
  );
}
export default VideoPlayer