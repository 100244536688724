function MyCourses() {
    return (
        <div className="myCourses">
            <div className="ud-container">
                <h1 className="ud-heading-page-title">
                    Quá trình học tập của tôi
                </h1>
            </div>
            <div className="tabs-module--tabs-container">
                <div className="ud-tabs-nav-buttons">
                    <section className="carousel-module" aria-label="Điều hướng tab">
                        <div className="carousel-module--scroll-port">
                            <div data-index="0" className="carousel-module--scroll-item">
                                <div className="ud-nav-button-container">
                                    <h2>
                                        <a id="tabs-1--tab-0" aria-selected="false" role="tab" tabIndex={-1} href="/courses/all-courses/"
                                            className="ud-btn">
                                            <span>Tất cả khóa học</span>
                                        </a>
                                    </h2>
                                </div>
                            </div>
                            <div data-index="1" className="carousel-module--scroll-item">
                                <div className="ud-nav-button-container">
                                    <h2>
                                        <a id="tabs-1--tab-1" aria-selected="true" role="tab" tabIndex={0} href="/courses/my-courses/"
                                            className="ud-btn">
                                            <span>Khóa học của tôi</span>
                                        </a>
                                    </h2>
                                </div>
                            </div>
                            <div data-index="2" className="carousel-module--scroll-item">
                                <div className="ud-nav-button-container">
                                    <h2>
                                        <a id="tabs-1--tab-2" aria-selected="false" role="tab" tabIndex={-1} href="/courses/wishlist/"
                                            className="ud-btn">
                                            <span>Mong muốn</span>
                                        </a>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="ud-tabs-content" role="tabpanel" id="tabs-1--content-0" tabIndex={-1} aria-labelledby="tabs-1--tab-0"></div>
                <div className="ud-tabs-content--tab-module-active" role="tabpanel" id="tabs-1--content-0" tabIndex={0} aria-labelledby="tabs-1--tab-1">
                    <div class="my-courses--main-content">
                        <div className="my-courses--card-grid">
                            <div>
                                <div className="enrolled-course-card--container">
                                    <div data-purpose="container" className="course-card-module--container course-card-module--medium">
                                        <div className="course-card-module--image-container">
                                            <img src="https://images.hcmcpv.org.vn/res/news/2019/06/12-06-2019-hon-4100-thi-sinh-thi-xet-tuyen-vao-lop-6-truong-thpt-chuyen-tran-dai-nghia-794DD25-details.JPG?vs=12062019024111"
                                                alt="" className="course-card-image-module--image" />
                                            <div className="enrolled-course-card--image-overlay">
                                                <span className="ud-play-overlay">
                                                    <svg aria-hidden="true" focusable="false" class="ud-icon ud-icon-xlarge">
                                                        <use href="#icon-play-overlay"></use>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="course-card-module--main-content">
                                            <div>
                                                <div className="course-card-title-module--title">
                                                    <h3 className="ud-heading-md course-card-title-module--course-title">
                                                        <a href="/course/abc/learn/lecture">TDN</a>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ud-tabs-content" role="tabpanel" id="tabs-1--content-0" tabIndex={-1} aria-labelledby="tabs-1--tab-2"></div>
            </div>
        </div>
    )
}

export default MyCourses