import axios from "axios";

const API_KEY = 'AIzaSyBsTTveLDsmi5pLXA_0hYGKDEPzjWU4frU'

const uploadFile = async (fileData) => {
    try {
        const url = 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart'

        const headers = {
            Authorization: API_KEY,
            'Content-Type': 'multipart/form-data',
        }

        const formData = new FormData()
        formData.append('file', fileData)

        const response = await axios.post(url, formData, { headers })
        if (response.status === 200) {
            console.log('File uploaded successfully!');
        } else {
            console.error('Error uploading file:', response.statusText);

        }
    } catch (error) {
        console.error('An error occurred:', error);
    }
    // if (response.data) {
    //     localStorage.setItem('file', JSON.stringify(esponse.data))
    // }
    // return response.data
}
const uploadService = {
    uploadFile,
}
export default uploadService