import { useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../../store/reducers/features/auth/authSlice'
import Spinner from '../../components/Spinner'

function TeacherLogin() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const { email, password } = formData

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isLoading } = useSelector((state) => state.auth)

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      email,
      password,
    }

    dispatch(login(userData))
      .unwrap()
      .then((user) => {
        // NOTE: by unwrapping the AsyncThunkAction we can navigate the user after
        // getting a good response from our API or catch the AsyncThunkAction
        // rejection to show an error message
        toast.success(`Logged in as ${user.username}`)
        navigate('/teacher-dashboard')
      })
      .catch(toast.error)
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <section className='teacher-heading'>
        <h1>
          Teacher Login
        </h1>
        <p>Please log in to get support</p>
      </section>

      <section className='auth-form'>
        <form onSubmit={onSubmit}>
          <div className='auth-form-group'>
            <input
              type='email'
              className='auth-form-control'
              id='email'
              name='email'
              value={email}
              onChange={onChange}
              placeholder='Enter your email'
              required
            />
          </div>
          <div className='auth-form-group'>
            <input
              type='password'
              className='auth-form-control'
              id='password'
              name='password'
              value={password}
              onChange={onChange}
              placeholder='Enter password'
              required
            />
          </div>
          <div className='auth-form-group'>
            <button className='signInButton'>Submit</button>
          </div>
        </form>
      </section>
      <Link to='/teacher-register' className='registerLink'>
        Register
      </Link>
    </>
  )
}

export default TeacherLogin