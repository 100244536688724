import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../../store/reducers/features/auth/authSlice'

import { ReactComponent as OfferIcon } from '../../assets/svg/exploreIcon.svg'
import { ReactComponent as ExploreIcon } from '../../assets/svg/tdn.svg'
import { ReactComponent as SearchIcon } from '../../assets/svg/searchIcon.svg'
import { ReactComponent as PersonOutlineIcon } from '../../assets/svg/personOutlineIcon.svg'


function NavBar() {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const user = useSelector((state) => state.auth)

    const onLogout = () => {
        dispatch(logout())
        // dispatch(reset())
        navigate('/')
    }

    const onLogin = () => {
        navigate('/sign-in')
    }

    const pathMatchRoute = (route) => {
        if (route == location.pathname) {
            return true
        }
    }
    return (<nav className="navbar">
        <nav className="navbarNar">
            <ul className="navbarListItems">
                <li className="navbarListItem" onClick={() => navigate('/')}>
                    <ExploreIcon fill={pathMatchRoute('/') ? '#2c2c2c' : '#8f8f8f'} width='84px' height='84px' />
                    <p className={pathMatchRoute('/') ? 'navbarListItemNameActive' : 'navbarListItemName'}>

                    </p>
                </li>
                <nav className='searchbar'>
                    <form className='searchbarForm'>
                        <button type="submit" className='btnSearch' disabled>
                            <SearchIcon width='24px' height='24px' />
                        </button>
                        <input className='searchField'
                            type="text"
                            aria-invalid="false"
                            placeholder="Tìm kiếm nội dung bất kỳ"
                            name="search"
                            autoComplete="off"
                            valuerole="combobox"
                            aria-autocomplete='both'
                            // aria-aria-haspopup="true"
                        />

                    </form>
                </nav>
                <li className="navbarListItem" onClick={() => navigate('/about')}>
                    <p className={pathMatchRoute('/about') ? 'navbarListItemNameActive' : 'navbarListItemName'}>
                        Về chúng tôi
                    </p>
                </li>
                <li className="navbarListItem" onClick={() => navigate('/explore')}>
                    <OfferIcon fill={pathMatchRoute('/offers') ? '#2c2c2c' : '#8f8f8f'} width='36px' height='36px' />
                    <p className={pathMatchRoute('/offers') ? 'navbarListItemNameActive' : 'navbarListItemName'}>

                    </p>
                </li>
                <li className="navbarListItem" onClick={() => navigate('/teacher-login')}>
                    <p className={pathMatchRoute('/teaching') ? 'navbarListItemNameActive' : 'navbarListItemName'}>
                        Giảng dạy tại đây
                    </p>
                </li>
                {user ? (
                    <li className="navbarListItem" onClick={onLogin}>
                        <p className={pathMatchRoute('/sign-in') ? 'navbarListItemNameActive' : 'navbarListItemName'}>
                            Đăng nhập
                        </p>
                    </li>
                ) : (
                    <li className="navbarListItem" onClick={onLogout}>
                        <PersonOutlineIcon fill={pathMatchRoute('/') ? '#2c2c2c' : '#8f8f8f'} width='84px' height='84px' />
                    </li>
                )}
            </ul>
        </nav>
    </nav>
    )
}

export default NavBar