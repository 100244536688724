import { combineReducers } from "@reduxjs/toolkit"
import authReducer from "./features/auth/authSlice"
import uploadReducer from "./features/upload/uploadSlice"

const RootReducer = combineReducers({
    upload: uploadReducer,
    auth: authReducer,
})

export default RootReducer
