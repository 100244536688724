import axios from "axios";

// const API_URL = 'https://api.tdnpassport.net/data/login/'
const REGISTER_API_URL = '/data/register/'
const LOGIN_API_URL = '/data/login/'

const register = async(userData) => {
    const response = await axios.post(REGISTER_API_URL, userData)
    if (response.data){
        localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
}
const login = async(userData) => {
    const response = await axios.post(LOGIN_API_URL, userData)
    if (response.data){
        localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
}
const logout = () => localStorage.removeItem('user')

const authService = {
    register,
    login,
    logout,
}

export default authService