const HomeBanner = ({ title, imageUrl }) => {
    return (
        <div style={{
            backgroundImage: `url(${imageUrl})`,
            height: '400px',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundSize: 'cover',
            backgroundPosition: 'bottom'
        }}>
            <h1 style={{
                fontSize: '56px',
                fontSize: '3.5rem',
                fontWeight: '400',

            }}>{title}</h1>
        </div>
    );
};

export default HomeBanner