import CategogyNavBar from '../components/navBar/CategoryNavBar'
import CoursesData from '../data/CoursesData'
import CourseCardList from '../components/CourseCardList'

import { useState } from 'react'
function Explore() {

    const [course, setCourseData] = useState(CoursesData)

    return (
        <div>
            <h1>Tất cả khóa học</h1>
            <CourseCardList course={course} />
        </div>
    )
}

export default Explore