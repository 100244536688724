import React from "react";
import {
    Slider, Button, Tooltip, Popover, Grid,

} from "@mui/material";
import { makeStyles, withStyles } from '@mui/styles';
import {
    FastForward,
    FastRewind,
    Pause,
    PlayArrow,
    SkipNext,
    VolumeUp,
    VolumeOff,
    FullscreenExit,
    Fullscreen

} from "@mui/icons-material";
import "./VideoControls.css";

const useStyles = makeStyles({
    volumeSlider: {
        width: "100px",
        color: "#9556CC",
    },

    bottomIcons: {
        color: "#999",
        padding: "12px 8px",


        "&:hover": {
            color: "#fff",
        },
    },
});
const PrettoSlider = withStyles({
    root: {
        height: "20px",
        color: "#9556CC",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: "#9556CC",
        border: "2px solid currentColor",
        marginTop: -3,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
        },
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)",
    },
    track: {
        height: 5,
        borderRadius: 4,
        width: "100%",
    },
    rail: {
        height: 5,
        borderRadius: 4,
    },
})(Slider);

const Control = ({

    onPlayPause,
    playing,
    onRewind,
    onForward,
    played,
    onSeek,
    onSeekMouseUp,
    volume,
    onVolumeChangeHandler,
    onVolumeSeekUp,
    mute,
    onMute,
    currentTime,
    duration,
    controlRef,
    isFullscreen,
    onFullScreen
}) => {
    return (
        <div className="control_Container" ref={controlRef}>
            <div className="top_container">
                {/* <h2>Video Player</h2> */}
            </div>

            <div className="mid__container">
                <div className="icon__btn" onClick={onRewind}>
                    <FastRewind fontSize="medium" />
                </div>

                <div className="icon__btn" onClick={onPlayPause}>
                    {playing ? (
                        <Pause fontSize="medium" />
                    ) : (
                        <PlayArrow fontSize="medium" />
                    )}
                </div>

                <div className="icon__btn" onClick={onForward}>
                    <FastForward fontSize="medium" />
                </div>
            </div>
            <div className="bottom__container">
                <div className="slider__container">
                    <Slider
                        min={0}
                        maz={100}
                        value={played * 100}
                        onChange={onSeek}
                        onChangeCommitted={onSeekMouseUp} />
                </div>
                <div className="control__box">
                    <div className="left__controls">
                        <div className="icon__btn" onClick={onPlayPause}>
                            {playing ? (
                                <Pause fontSize="medium" />
                            ) : (
                                <PlayArrow fontSize="medium" />
                            )}{" "}
                        </div>
                        <div className="icon__btn">
                            <SkipNext fontSize="medium" />
                        </div>
                        <div className="icon__btn" onClick={onMute} >
                            {mute ? (
                                <VolumeOff fontSize="medium" />
                            ) : (
                                <VolumeUp fontSize="medium" />
                            )}
                        </div>

                        <Slider
                            onChange={onVolumeChangeHandler}
                            className={'${classes.volumeSlider}'}
                            value={volume * 100}
                            onChangeCommitted={onVolumeSeekUp} />
                        <span>{currentTime} : {duration}</span>
                    </div>
                    <div className="right__controls">
                        <div className="icon__btn" onClick={onFullScreen}>
                            {!isFullscreen ? (
                                <Fullscreen fontSize="medium" />
                            ) : (
                                <FullscreenExit fontSize="medium" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Control