import React from "react"
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from "./store/reducers/RootReducer"
import App from "./App"
import './index.css';
import { createRoot } from "react-dom/client"

const reduxStore = configureStore({
    reducer: rootReducer
});

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
    <Provider store={reduxStore}>
        <App />
    </Provider>
)