import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';

import HeaderNavBar from './components/navBar/HeaderNavbar'
import { ToastContainer } from 'react-toastify';
import FooterNavBar from './components/navBar/FooterNavBar'
import SignIn from './pages/AccountPage/SignIn'
import SignUp from './pages/AccountPage/SignUp'
import CategogyNavBar from './components/navBar/CategoryNavBar'
import Banner from './components/HomeBanner'

// import VideoPlayer from './components/VideoPlayer'

import Explore from './pages/Explore'
import Offers from './pages/LandingPage/Offers'

import ForgotPassword from './pages/AccountPage/ForgotPassword'

import AboutPage from './pages/LandingPage/AboutPage'
import TdnLearning from './pages/LandingPage/TdnLearning'
import SatLearning from './pages/LandingPage/SatLearning'
import GradeLearning from './pages/LandingPage/GradeLearning'
import Dashboard from './pages/Dashboard'

import TeacherLogin from './pages/TeachingPage/TeacherLogin'
import TeacherRegister from './pages/TeachingPage/TeacherRegister'

import WishList from './pages/CoursePage/WishList'
import MyCourses from './pages/CoursePage/MyCourses'
import VideoPlayer from './pages/Course/VideoPlayer'
import TeacherDashboard from './pages/TeachingPage/TeacherDashboard'

import Upload from './pages/UploadFile'

function App() {

    return (
        <>
            <Router>
                <HeaderNavBar />
                <Routes>
                    <Route exact path='/' element={
                        <div>
                            <CategogyNavBar />
                            <Banner
                                title="Chào mừng đến với website của chúng tôi!"
                                imageUrl="https://thongtintuyensinh.net/wp-content/uploads/2019/09/thpt-chuyen-tran-dai-nghia-ngung-tuyen-sinh-lop-chuyen-tin.jpg"
                            />
                        </div>
                    }></Route>
                    <Route path='/offers' element={<Offers />} />
                    <Route path='/about' element={<AboutPage />} />
                    <Route path='/explore' element={<Explore />} />
                    <Route path='/teacher-login' element={<TeacherLogin />} />
                    <Route path='/teacher-register' element={<TeacherRegister />} />
                    <Route path='/sign-in' element={<SignIn />} />

                    <Route path='/sign-up' element={<SignUp />} />
                    <Route path='/forgot-password' element={<ForgotPassword />} />

                    <Route path='/sat' element={<SatLearning />} />
                    <Route path='/tdn' element={<TdnLearning />} />

                    <Route path='/grade' element={<GradeLearning />} />

                    <Route path='/courses/my-courses' element={<MyCourses />} />
                    <Route path='/courses/wishlist' element={<WishList />} />
                    <Route path='/course/abc/learn/lecture' element={<VideoPlayer />} />
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/upload' element={<Upload />} />
                    <Route path='/teacher-dashboard' element={<TeacherDashboard/>} />
                    {/* <Route path='/course/video-player' element={<VideoPlayer/>}/> */}
                </Routes>
                <FooterNavBar />

            </Router>
            <ToastContainer />
        </>
    )
}

export default App